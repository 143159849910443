import React from "react";
import Founder1 from "../assets/founder1.png";
import Founder2 from "../assets/Aditya.png";
import Founder3 from "../assets/Manish.png";
import Founder4 from "../assets/Chris Key.png";
import LinkedInSVG from "../assets/id-links.svg";
function Founders() {
  return (
    <section className="founders-section" id="founders-page">
      <h2>OUR FOUNDERS</h2>

      <span>Domain Expertise Meets Deep-Tech Expertise</span>

      <div className="founders-details">
        <div className="wrapper">
          <div className="container">
            <div className="image-div">
              <img src={Founder1} alt="" />
            </div>
            <h4>Varun Mowar</h4>
            <span>CEO and Founder</span>
            <div className="about-the-founder">
              <p>
                20 years of experience in fraud risk and regulatory risk
                investigation for corporates, investors and banks.
              </p>
              <p>
                Advised on fraud risk with Tier I investors like Softbank,
                Lightspeed, Abu Dhabi Investment Authority (ADIA), NIIF, KKR,
                Blackstone, Actis, Tiger Global US.
              </p>
              <p>
                Consulted with large corporates on fraud, risk management &
                investigations - Vedanta, Jindal, Adobe, IBM, Microsoft and
                several large international law firms.
              </p>
              <p>Worked with A&M, Adobe Software, KPMG and Deloitte.</p>
              <p>Chartered accountant and London Business School MBA.</p>
            </div>
          </div>
          <div className="linkedin">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/in/vmowar/"
            >
              <span>Varun Mowar On linkedIn</span>
              <img src={LinkedInSVG} alt="linkedin" />
            </a>
          </div>
        </div>
        <div className="wrapper">
          <div className="container">
            <div className="image-div">
              <img src={Founder2} alt="" />
            </div>
            <h4>Adityavijay Rathore</h4>
            <span>Co-founder and CPO</span>
            <div className="about-the-founder">
              <p>
                19 years of experience in enterprise analytics and
                transformative journeys for global leaders.
              </p>
              <p>
                Successful full-cycle entrepreneur, previous startup Byte
                Prophecy was acquired by Accenture – first acquisition in India.
              </p>
              <p>
                Consulted with large corporates on enterprise analytics-led
                transformations - Airtel, HT Media, Welspun, Sprint, Verizon,
                Telstra, Singtel.
              </p>
              <p>
                Worked with Accenture Strategy, Byte Prophecy (acquired by
                Accenture) and Guavus (acquired by Thales).
              </p>
              <p>
                Mechanical Engineer with MS in Industrial Engineering (Arizona).
              </p>
            </div>
          </div>
          <div className="linkedin">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/in/avrathore/"
            >
              <span>Adityavijay Rathore on LinkedIn</span>
              <img src={LinkedInSVG} alt="linkedin" />
            </a>
          </div>
        </div>
        <div className="wrapper">
          <div className="container">
            <div className="image-div">
              <img src={Founder3} alt="" />
            </div>
            <h4>Manish Patil</h4>
            <span>Co-founder and CTO</span>
            <div className="about-the-founder">
              <p>
                25 years of experience in the IT industry focusing on
                Architecture, Big-Data and Applied Artificial Intelligence.
              </p>
              <p>
                Successful full-cycle entrepreneur, previous startup Byte
                Prophecy was acquired by Accenture Strategy – led the technical
                team.
              </p>
              <p>
                Led AI model implementation in various industries like Telecom
                (Airtel, Sprint, etc.), Advertising (HT Media, Specific Media -
                4 Patents), Manufacturing (HUL, Wipro, MRF), Pharma (Eris, Sun).
              </p>
              <p>
                Worked with Accenture Strategy, Byte Prophecy (acquired by
                Accenture) and Guavus (acquired by Thales) .
              </p>
              <p>B. Tech, Chemical Engineer (IIT Bombay).</p>
            </div>
          </div>
          <div className="linkedin">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/in/patilmanishh/"
            >
              <span>Manish Patil on LinkedIn</span>
              <img src={LinkedInSVG} alt="linkedin" />
            </a>
          </div>
        </div>
        <div className="wrapper">
          <div className="container">
            <div className="image-div">
              <img src={Founder4} alt="" />
            </div>
            <h4>Chris Key</h4>
            {/* <span>GTM Leader, America</span> */}
            <span>Co-founder</span>
            <div className="about-the-founder">
              <p>
                Enterprise GTM pro with success in driving transformation vision
                and delivering revenue growth for emerging, high-growth tech
                innovators.
              </p>
              <p>
                Demonstrated achievement in building sales cultures,
                accelerating revenue and outperforming milestones.
              </p>
              <p>
                Experienced in complex enterprise organizations, scale-up orgs,
                and fluid start-up environments.
              </p>
              <p>
                Proven capability in driving disruptive technology from
                incubation to market leadership.
              </p>
              <p>
                Skilled at executing major sales strategies, cultivating C-Level
                champions, and driving strategic partnerships and alliances.
              </p>
              <p>
                International business experience in Europe, North America,
                India and Asia.
              </p>
            </div>
          </div>
          <div className="linkedin">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/in/chriskeynyc/"
            >
              <span>Chris Key on LinkedIn</span>
              <img src={LinkedInSVG} alt="linkedin" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Founders;
